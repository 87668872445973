import type { FunctionComponent } from "react";

import { Container, Flag, FlagContainer, Loader } from "./styles";

export type Props = {
  className?: string;
};
export const WheelIcon: FunctionComponent<Props> = ({ className }) => (
  <Container className={className}>
    <Loader />
    <FlagContainer>
      <Flag />
    </FlagContainer>
  </Container>
);

WheelIcon.displayName = "WheelIcon";

import { addDays as addD, addMonths as addM, addMinutes as addMin, addSeconds as addSec, differenceInHours, differenceInMinutes, isAfter, isEqual, isSameDay, isValid, parseISO } from "date-fns";
import { formatInTimeZone as formatInTZ } from "date-fns-tz";
import { toString } from "duration-fns";
import { config } from "shared/data";
import { isNumeric, isString } from "./common";
export function addDays(value, amount) {
  return addD(parseDate(value), amount);
}
export function addMinutes(value, amount) {
  return addMin(parseDate(value), amount);
}
export function addMonths(value, amount) {
  return addM(parseDate(value), amount);
}
export function addSeconds(value, amount) {
  return addSec(parseDate(value), amount);
}
export function durationToISOString(minutes, seconds) {
  return toString({
    minutes,
    seconds
  });
}
export function formatDistanceToNow(value) {
  const now = Date.now();
  const parsed = parseDate(value);
  const minutes = differenceInMinutes(now, parsed, {
    roundingMethod: "round"
  });
  const hours = differenceInHours(now, parsed, {
    roundingMethod: "round"
  });
  switch (true) {
    case minutes <= 1:
      return "1 minute ago";
    case minutes > 1 && minutes < 60:
      return `${minutes} minutes ago`;
    case hours === 1:
      return `1 hour ago`;
    case hours > 1 && hours <= 6:
      return `${hours} hours ago`;
    default:
      return formatInTimeZone(parsed);
  }
}
export function formatInTimeZone(value) {
  let format = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : config.date.defaultFormat;
  let timeZone = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : config.date.defaultTimeZone;
  return formatInTZ(parseDate(value), timeZone, format);
}
export function isAfterOrEqual(value, compareValue) {
  const dateA = parseDate(value);
  const dateB = parseDate(compareValue);
  return isAfter(dateA, dateB) || isEqual(dateA, dateB);
}
export function isToday(value) {
  return isSameDay(parseDate(value), new Date());
}
export function isYesterday(value) {
  return isSameDay(parseDate(value), addDays(new Date(), -1));
}
export function parseDate(value) {
  if (isString(value)) {
    const parsed = parseISO(value);
    return isValid(parsed) ? parsed : new Date(value);
  } else if (isNumeric(value)) {
    return new Date(value);
  }
  return value;
}
export function toISOString(value) {
  return parseDate(value).toISOString();
}
export const getReadingTime = function () {
  let time = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 1;
  let suffixSingle = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : " minute";
  let suffixPlural = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : " minutes";
  if (time > 1) {
    return `${time}${suffixPlural}`;
  }
  return `1${suffixSingle}`;
};
import { forwardRef } from "react";

import { Variant } from "./enums";
import { CenteredContainer, SpinnerIcon } from "./styles";
import { WheelIcon } from "./wheel-icon";

export type Props = {
  className?: string;
  variant?: Variant;
};

export const Loading = forwardRef<HTMLImageElement, Props>(
  ({ className, variant = Variant.Wheel, ...attributes }, reference) => {
    const getSource = () => {
      switch (variant) {
        case Variant.Spinner:
          return <SpinnerIcon />;
        case Variant.Wheel:
          return <WheelIcon />;
      }
    };

    const source = getSource();

    return (
      <CenteredContainer className={className} {...attributes}>
        <div ref={reference}>{source}</div>
      </CenteredContainer>
    );
  },
);

Loading.displayName = "Loading";

import styled from "@emotion/styled";

export const Container = styled.div`
  position: relative;
`;

export const Loader = styled.div`
  inline-size: 122px;

  border: 2px solid;
  border-radius: 50%;

  animation: l2 1s infinite linear;
  aspect-ratio: 1;
  border-inline-end-color: #f8cc46;
  border-inline-start-color: #e7e7e6;
  border-block-color: #e7e7e6;

  @keyframes l2 {
    to {
      transform: rotate(1turn);
    }
  }
`;

export const FlagContainer = styled.div`
  position: absolute;
  inset-block-start: 50%;
  inset-inline-start: 50%;

  display: flex;

  inline-size: 40.98%;
  block-size: 31.14%;

  transform: translate(-50%, -50%);
`;

export const Flag = styled.div`
  flex: 1;

  border: 4px solid #e7e7e6;

  background: #e7e7e6;

  &::before,
  &::after {
    position: absolute;
    inset-block-start: 50%;
    content: "";

    inline-size: calc(50% - 6px);
    block-size: calc(100% - 8px);

    border: 1px solid transparent;

    background-color: #ffffff;

    transform: translateY(-50%);
    transform: translateY(-50%);
  }

  &::before {
    inset-inline-start: 4px;
  }

  &::after {
    inset-inline-end: 4px;
  }
`;

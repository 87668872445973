import styled from "@emotion/styled";

export const CenteredContainer = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: stretch;
`;

export const SpinnerIcon = styled.div`
  display: grid;

  inline-size: 100px;

  border-radius: 50%;

  background:
    linear-gradient(0deg, rgb(0 0 0/0.5) 30%, #00000000 0 70%, rgb(0 0 0/1) 0) 50%/8% 100%,
    linear-gradient(90deg, rgb(0 0 0/0.25) 30%, #00000000 0 70%, rgb(0 0 0/0.75) 0) 50%/100% 8%;
  background-repeat: no-repeat;

  animation: l23 1s infinite steps(12);
  aspect-ratio: 1;
  @keyframes l23 {
    100% {
      transform: rotate(1turn);
    }
  }

  &::before,
  &::after {
    grid-area: 1/1;

    content: "";

    border-radius: 50%;

    background: inherit;

    opacity: 0.915;
    transform: rotate(30deg);
  }
  &::after {
    opacity: 0.83;
    transform: rotate(60deg);
  }
`;
